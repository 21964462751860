import React from "react"
import {
  AutoComplete,
  Button,
  Card,
  Col,
  Input,
  Modal,
  Popconfirm,
  Popover,
  Row,
  Switch,
  Tabs,
  Tooltip,
  Typography,
  DatePicker,
  Checkbox,
} from "antd"
import { isEmpty } from "lodash"
import { generateId } from "../../common/helpers"
import AddTag from "../add_tag"
import { updateJobTH } from "../../store/actions/jobs/update-job"
import { FeaturesTypes, JOB_APPLIED, JOB_DISCARDED } from "../../common/constants"
import moment from "moment"
import {
  CommunicationList,
  DiscardChanges,
  KeyDates,
} from "../index"
import { DATE_FORMAT } from "../../common/config"
import { TodoList } from "../TodoList"
import OutsideClickHandler from "react-outside-click-handler"
import {
  CloseCircleFilled,
  EditOutlined,
  PlusCircleFilled,
  LinkOutlined,
  CloseCircleOutlined
} from "@ant-design/icons"
import { ResumeManager } from "../leads/resume_manager"
import withMemo from "../../store/HOC/with_memo"
import { useAddLead } from "../../hooks/useAddLead"
import ModalCloseIcon from "./modal-close-icon"
import { LinkItem } from "../notes/link_item"
import { TodoItem } from "../TodoItem"
import Spinner, { MiniSpinner } from '../loaders/spinner'

const { Title, Text } = Typography

const { Option } = AutoComplete
const AddLead = ({ isEdit, isAdd, onClose, style, saveCompany }) => {
  const [defaultActiveKey, setDefaultActiveKey] = React.useState("1")
  const {
    urlIsEdit,
    setUrlIsEdit,
    handleAddLead,
    handleCloseOrConfirm,
    data,
    handleEditLead,
    linkInputEl,
    hide,
    popOverVisible,
    handleClickOnLinkedin,
    specificLead,
    addTagStep,
    inputRef,
    setAddTagStep,
    // companyIsEmpty,
    canShow,
    handleSearch,
    setCanShow,
    options,
    handleOpenResumeModal,
    setData,
    handleJobApply,
    checkFollow,
    isLoading,
    handleOpenCompanyEdit,
    confirmModal,
    setConfirmModal,
    handleUploadResume,
    toggleCommunicationModal,
    renderResumeTitle,
    resetResume,
    renderDueDate,
    handleDeleteOrDiscard,
    todos,
    draftTodos,
    handleSaveTodo,
    communications,
    openResume,
    fileTitle,
    setFileTitle,
    closeResume,
    leadModalRef,
    companyNameRef,
    handleOutsideClick,
    setCompanyIsEdit,
    companyIsEdit,
    isClickable,
    datePickerRef,
    pickerIsOpen,
    disabledDate,
    closePicker,
    onOpenPicker,
    onChangeDatePicker,
    onTodoCheckBoxChange,
    setIsEditTodo,
    isEditTodo,
    ownTodoDesc,
    setOwnTodoDesc,
    specificLeadOwnTodo,
    handleEnterBlurOwnTodo,
    onHoverDate,
    mouseHover,
    handleAutoCreateWithFileLead,
    leadConfig,
    CRM_MODE,
    subType,
    setPickerIsOpen,
    isSearchCompanyLoading,
    isContactEdit,
    setContactEdit,
    contactNameRef,
    debouncedContactSearch,
    contactsResults,
    isClickableContact,
    handleOpenContactEdit,
    isSearchContactLoading,
    onBlurCompanyInput,
    onBlurContactInput,
    setSearchContactList,
    setSearchCompanyList,
    titleRef,
    isTitleEmpty
  } = useAddLead(isAdd, isEdit, onClose, setDefaultActiveKey, saveCompany)

  const itemsTabPane = [
    {
      key: "1",
      label: `${leadConfig.name} Info`,
      children: (
        <div style={{ minHeight: "472px" }}>
          <Row style={{ height: "100%" }} ref={leadModalRef} id="key">
            <Col span={13}>
              <Title className="contact-modal title name" level={5}>
                Title
                {isTitleEmpty() && canShow && (
                  <span style={{ color: "red", marginLeft: "0.5rem" }}>
                    Please enter a title
                  </span>
                )}
              </Title>
              <Input
                data-name="title"
                ref={titleRef}
                tabIndex="1"
                className="contact-modal inpunt"
                value={data.title}
                onChange={handleEditLead}
              />
              <Title className="contact-modal title " level={5}>
                Location
              </Title>
              <Input
                data-name="location"
                value={data.location}
                onChange={handleEditLead}
                tabIndex="1"
                className="contact-modal inpunt"
              />
              <div
                style={{
                  display: "flex",
                  marginTop: "12px",
                  marginBottom: "4px",
                  justifyContent: "space-between",
                }}
              >
                <Title className="contact-modal title desc" level={5}>
                  Description
                </Title>
                <Popover
                  content={
                    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
                      {!data?.url || urlIsEdit ? (
                        <Input
                          tabIndex="-1"
                          ref={(input) => {
                            linkInputEl.current = input
                          }}
                          autoFocus
                          data-name="url"
                          value={data.url}
                          onChange={(e) => {
                            setUrlIsEdit(true)
                            handleEditLead(e.target.value, "url")
                          }}
                          onPressEnter={hide}
                          className="contact-modal inpunt share-inpunt"
                          allowClear
                          placeholder="Link"
                        />
                      ) : (
                        <>
                          <LinkItem link={data.url} file />
                          <EditOutlined
                            className="lead-modal description edit-icon"
                            onClick={() => setUrlIsEdit((prev) => !prev)}
                            style={{ marginRight: 5 }}
                          />
                          <Popconfirm
                            placement="right"
                            className="delete_file"
                            title="Clear link ?"
                            onConfirm={() => {
                              hide()
                              setTimeout(() => handleEditLead("", "url"), 500)
                            }}
                            okText="Clear"
                            cancelText="Cancel"
                          >
                            <CloseCircleOutlined style={{ color: "red", fill: "red", cursor: "pointer" }} />
                          </Popconfirm>
                        </>

                      )}

                    </OutsideClickHandler>
                  }
                  trigger="click"
                  open={popOverVisible}
                  onOpenChange={(visible) => {
                    if (visible && linkInputEl.current) {
                      setTimeout(() => {
                        linkInputEl.current?.focus()
                      }, 300)
                    }
                  }}
                >
                  <Tooltip
                    overlayClassName={
                      data.url ? "d_f" : popOverVisible ? "d_f" : ""
                    }
                    placement="top"
                    title="Сlick to attach Link"
                  >

                    <LinkOutlined width={20}
                      onClick={handleClickOnLinkedin}
                      style={
                        {
                          marginTop: "2px",
                          color: !data.url ? "#8c8c8c" : "#009EEF",
                          fontSize: '17px'
                        }}
                    />

                  </Tooltip>
                </Popover>
              </div>

              <Input.TextArea
                tabIndex="2"
                data-name="description"
                className="contact-modal inpunt notes description"
                value={data.description}
                onChange={handleEditLead}
              />
              <AddTag
                item={specificLead}
                addTagStep={addTagStep}
                inputRef={inputRef}
                setAddTagStep={setAddTagStep}
                update={updateJobTH}
                type={FeaturesTypes.LEAD}
              />
              <div>
                <Button
                  style={{ position: "absolute", bottom: "0", left: "0" }}
                  type="primary"
                  onClick={handleAddLead}
                >
                  Done
                </Button>
              </div>
            </Col>
            <Col
              span={11}
              style={{
                textAlign: "right",
              }}
            >
              <Title className="contact-modal right title name" level={5}>
                Company
              </Title>
              <div
                style={{
                  textAlign: "center",
                  justifyContent: "initial",
                  height: "32px",
                }}
              >
                {!data?.company?.name || companyIsEdit
                  ?
                  <div style={{ position: "relative" }}>
                    {isSearchCompanyLoading && <div style={{ position: 'absolute', right: "10%", top: '10%', zIndex: 10 }}>
                      <MiniSpinner />
                    </div>}
                    <AutoComplete
                      ref={companyNameRef}
                      autoFocus
                      style={{ borderColor: "red" }}
                      tabIndex="3"
                      className="contact-modal auto-complete lead"
                      onSearch={handleSearch}
                      allowClear
                      defaultValue={data?.company?.name}
                      onSelect={onBlurCompanyInput}
                      onBlur={onBlurCompanyInput}
                      onChange={(val) => {
                        canShow && setCanShow(false)
                        setCompanyIsEdit(true)
                        handleEditLead(val, "company")
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setCompanyIsEdit(false)
                        }
                      }}
                      value={data?.company?.name}
                    >

                      {data?.company?.name?.trim() && options.map((company) => (
                        <Option
                          onClick={() => {
                            handleEditLead(company, "company")
                          }}
                          value={company.name}
                          key={generateId()}
                        >
                          {company.name}
                        </Option>
                      ))}
                    </AutoComplete>
                  </div>
                  :
                  <div style={{

                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: '4px 1px 7px 35px',
                  }}>
                    <span
                      className="search-span-result"
                      onClick={handleOpenCompanyEdit}
                      style={{
                        color: isClickable ? "#009EEF" : "rgba(0, 0, 0, 0.25)",
                        cursor: isClickable ? "pointer" : "default",
                        pointerEvents: isClickable ? "auto" : "none",
                      }}>
                      {data?.company?.name}
                    </span>
                    <span>
                      <EditOutlined
                        className="lead-modal description edit-icon"
                        onClick={() => setCompanyIsEdit((prev) => !prev)}
                        style={{ marginRight: 5 }}
                      />
                      <Popconfirm
                        placement="right"
                        className="delete_file"
                        title="Clear company ?"
                        onConfirm={() => {
                          setTimeout(() => {
                            handleEditLead("", "company")
                            setSearchCompanyList([])
                          }, 500)
                        }}
                        okText="Clear"
                        cancelText="Cancel"
                      >
                        <CloseCircleOutlined style={{ color: "red", fill: "red", cursor: "pointer" }} />
                      </Popconfirm>
                    </span>
                  </div>
                }


              </div>

              <div style={{ display: "flex", flexDirection: "column", textAlign: "left", position: "relative" }}>
                <div style={{ paddingLeft: 22 }}>
                  <Title className="contact-modal title " level={5}>
                    Contact
                    {/* {contactIsEmpty() && canContactShow && (
                      <span style={{ color: "red", marginLeft: "1rem" }}>
                        {config.contact_empty_message}
                      </span>
                    )} */}
                  </Title>
                </div>
                {!data?.contact?.first_name || isContactEdit ?
                  <div style={{ paddingLeft: 25 }}>
                    {isSearchContactLoading && <div style={{ position: 'absolute', right: "10%", bottom: '10%', zIndex: 10 }}>
                      <MiniSpinner />
                    </div>}
                    <AutoComplete
                      ref={contactNameRef}
                      autoFocus
                      style={{ borderColor: "red", }}
                      tabIndex="3"
                      className="contact-modal auto-complete lead"
                      onSearch={debouncedContactSearch}
                      allowClear
                      defaultValue={data?.contact?.first_name}
                      onSelect={onBlurContactInput}
                      onBlur={onBlurContactInput}
                      onChange={(val) => {
                        setContactEdit(true)
                        handleEditLead(val, "contact")
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setContactEdit(false)
                        }
                      }}
                      value={data?.contact?.first_name}
                    >
                      {data?.contact?.first_name?.trim() && contactsResults.map((contact) => (
                        <Option
                          onClick={() => {
                            handleEditLead(contact, "contact")
                          }}
                          value={contact.first_name}
                          key={contact.id}
                        >
                          {contact.first_name.name}
                        </Option>
                      ))}
                    </AutoComplete>
                  </div> : <div style={{

                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: '4px 1px 7px 35px'
                  }}>
                    <span
                      className="search-span-result"
                      onClick={handleOpenContactEdit}
                      style={{
                        color: isClickableContact ? "#009EEF" : "rgba(0, 0, 0, 0.25)",
                        cursor: isClickableContact ? "pointer" : "default",
                        pointerEvents: isClickableContact ? "auto" : "none",
                      }}>
                      {data?.contact?.first_name}
                    </span>
                    <span>
                      <EditOutlined
                        className="lead-modal description edit-icon"
                        onClick={() => setContactEdit((prev) => !prev)}
                        style={{ marginRight: 5 }}
                      />
                      <Popconfirm
                        placement="right"
                        className="delete_file"
                        title="Clear contact ?"
                        onConfirm={() => {
                          setTimeout(() => {
                            handleEditLead("", "contact")
                            setSearchContactList([])
                          }, 500)
                        }}
                        okText="Clear"
                        cancelText="Cancel"
                      >
                        <CloseCircleOutlined style={{ color: "red", fill: "red", cursor: "pointer" }} />
                      </Popconfirm>
                    </span>
                  </div>}

              </div>
              {subType !== CRM_MODE &&
                <div
                  style={{
                    display: "flex",
                    marginTop: "12px",
                    marginBottom: "4px",
                    height: "22px",
                  }}
                >


                  <Title className="contact-modal right title resume" level={5}>
                    Resume:
                  </Title>
                  <Text
                    style={{
                      marginLeft: "18px",
                    }}
                  >
                    {renderResumeTitle() ? (
                      renderResumeTitle()
                    ) : (
                      <span style={{ color: "#BFBFBF" }}>add resume</span>
                    )}
                  </Text>
                  <div style={{ marginLeft: "auto" }}>
                    {!isEmpty(specificLead.resume) ? (
                      <CloseCircleFilled
                        data-name
                        className="contact-modal resume-icon close"
                        onClick={resetResume}
                      />
                    ) : (
                      <PlusCircleFilled
                        onClick={handleOpenResumeModal}
                        className="contact-modal resume-icon"
                      />
                    )}
                  </div>


                </div>
              }

              <Title className="contact-modal right title" level={5}>
                Notes
              </Title>
              <Input.TextArea
                value={data.notes}
                onChange={(e) => setData({ ...data, notes: e.target.value })}
                tabIndex="4"
                className={`contact-modal inpunt right notes leads ${subType === CRM_MODE ? "workspace" : ""}`}
              />
              <div
                style={{
                  display: "flex",
                  marginTop: "12px",
                  marginBottom: "4px",
                }}
              >
                <Title className="contact-modal right title resume" level={5}>
                  {leadConfig.applied}
                </Title>
                <Switch
                  className="lead-modal switch"
                  checked={data.status_id === JOB_APPLIED}
                  onChange={handleJobApply}
                  style={{ marginLeft: "25px", marginRight: "15px" }}
                />

                {data.status_id === JOB_APPLIED && (
                  <Card className="lead-modal card">
                    <p style={{ marginBottom: "0px" }}>
                      {moment(data.apply_dt).format(DATE_FORMAT)}
                    </p>
                  </Card>
                )}
              </div>
              {isLoading && <div style={{ position: 'absolute', right: "45%" }}><Spinner /></div>}
              {!specificLead?.todos?.length && <div style={{ padding: 10 }}></div>}
              {specificLead?.todos?.length > 0 &&
                <div style={{ display: "flex", padding: "10px 0px 0px 25px" }}>

                  <Checkbox
                    className="todo-check"
                    checked={specificLeadOwnTodo?.status_id === 2}
                    style={{ marginRight: "24px", height: "24px" }}
                    onChange={onTodoCheckBoxChange}
                  />
                  {isEditTodo ?
                    <Input
                      onKeyDown={(e) => {
                        if (e.keyCode === 27) {
                          e.stopPropagation()
                          setIsEditTodo(false)
                        }
                      }}
                      autoFocus
                      style={{ width: "100%", height: "24px" }}
                      onBlur={handleEnterBlurOwnTodo}
                      value={ownTodoDesc}
                      onPressEnter={handleEnterBlurOwnTodo}
                      defaultValue={ownTodoDesc}
                      onChange={(e) => setOwnTodoDesc(e.currentTarget.value)}
                    /> : <>
                      <TodoItem setEditableTodo={setIsEditTodo} handleEdit={() => setOwnTodoDesc(specificLeadOwnTodo?.description)} todo={specificLeadOwnTodo} placement="modal" />

                      {checkFollow() && (
                        <Card
                          style={{ borderRadius: "5px", cursor: "pointer", borderColor: onHoverDate ? "#009EEF" : "rgba(0, 0, 0, 0.05)" }}
                          onClick={onOpenPicker}
                          className="lead-modal card"
                          onMouseEnter={
                            mouseHover}
                          onMouseLeave={mouseHover}
                        >
                          <p style={{ marginBottom: "0px" }}>{renderDueDate()}</p>
                          {pickerIsOpen &&
                            <DatePicker
                              id="date-lead-picker"
                              allowClear={false}
                              style={{ position: "absolute", top: "-1px", right: '-1px', width: "114px" }}
                              open
                              onKeyDown={closePicker}
                              autoFocus
                              showNow={false}
                              picker="date"
                              disabledDate={disabledDate}
                              format="MMM DD"
                              ref={datePickerRef}
                              placeholder="Today"
                              onOpenChange={setPickerIsOpen}
                              onChange={onChangeDatePicker}
                            />
                          }
                        </Card>
                      )}
                    </>
                  }


                </div>
              }


              <div style={{ height: "56px" }}>
                {specificLead.status_id === JOB_DISCARDED ? (
                  <Popconfirm
                    placement="topRight"
                    className="delete_file"
                    title={`Delete ${leadConfig.name}? There is no undo.`}
                    onConfirm={() => {
                      handleDeleteOrDiscard(true)
                    }}
                    okText="Delete"
                    cancelText="Cancel"
                  >
                    <Button
                      danger
                      type="primary"
                      style={{
                        position: "absolute",
                        bottom: "0",
                        right: "4px",
                        marginLeft: "21px",
                      }}
                    >
                      Delete {leadConfig.name}
                    </Button>
                  </Popconfirm>
                ) : (
                  <Tooltip placement="top" title={`Discard this ${leadConfig.name}`}>
                    <Button
                      onClick={() => handleDeleteOrDiscard()}
                      danger
                      type="primary"
                      style={{
                        position: "absolute",
                        bottom: "0",
                        right: "4px",
                        marginLeft: "21px",
                      }}
                    >
                      Discard {leadConfig.name}
                    </Button>
                  </Tooltip>
                )}
              </div>
            </Col>
          </Row>
        </div>
      ),
    },
    {
      key: "2",
      label: "Tools",
      children: (
        <div style={{ minHeight: "472px" }} disabled={!data.id}>
          {isLoading && <div style={{ position: 'absolute', right: "45%" }}><Spinner /></div>}
          <Row style={{ height: "100%" }}>
            <Col span={13}>
              <Title className="contact-modal todo-title" level={3}>
                Tasks
              </Title>
              <TodoList
                todos={isAdd ? draftTodos : todos.filter((todo) => todo.job === specificLead.id)}
                containerId={specificLead.id}
                showTodoInput
                handleSaveTodo={isAdd ? handleSaveTodo : undefined}
                isModal={true}
              />
              <Title className="contact-modal title notes" level={5}>
                Notes
              </Title>
              <Input.TextArea
                className="contact-modal inpunt notes workspace"
                value={data.notes}
                onChange={(e) => setData({ ...data, notes: e.target.value })}
              />
              <div style={{ height: "32px" }}>
                <Button
                  style={{ position: "absolute", bottom: "0", left: "0" }}
                  type="primary"
                  onClick={handleAddLead}
                >
                  Done
                </Button>
              </div>
            </Col>
            <Col span={11}>
              <CommunicationList
                toggleCommuniCationModal={toggleCommunicationModal}
                communications={
                  isEdit ? communications.published?.filter(el => el?.job === specificLead?.id) : communications.drafts
                }
              />
              <div style={{ height: "48px" }}>

                {specificLead.status_id === JOB_DISCARDED ? (
                  <Popconfirm
                    placement="topRight"
                    className="delete_file"
                    title={`Delete ${leadConfig.name}? There is no undo.`}
                    onConfirm={() => {
                      handleDeleteOrDiscard(true)
                    }}
                    okText="Delete"
                    cancelText="Cancel"
                  >
                    <Button
                      danger
                      type="primary"
                      style={{
                        position: "absolute",
                        bottom: "0",
                        left: "0",
                        marginLeft: "21px",
                      }}
                    >
                      Delete {leadConfig.name}
                    </Button>
                  </Popconfirm>
                ) : (
                  <Tooltip placement="top" title={`Discard this ${leadConfig.name}`}>
                    <Button
                      onClick={() => handleDeleteOrDiscard()}
                      danger
                      type="primary"
                      style={{
                        position: "absolute",
                        bottom: "0",
                        left: "0",
                        marginLeft: "21px",
                      }}
                    >
                      Discard {leadConfig.name}
                    </Button>
                  </Tooltip>
                )}
                <KeyDates
                  lead={specificLead}
                  applied={data.status_id === JOB_APPLIED}
                  appliedTitle={leadConfig.applied}
                  discardedTitle={leadConfig.done}
                  follow_up={checkFollow}
                  discarded={data.status_id === JOB_DISCARDED}

                />
              </div>
            </Col>
          </Row>
        </div>
      ),
    },
  ]
  return (
    <>
      <Modal
        closeIcon={<ModalCloseIcon />}
        open
        onOk={handleAddLead}
        onCancel={handleCloseOrConfirm}
        footer={null}
        width={800}
        style={style}
      >
        <Tabs style={{ pointerEvents: isLoading ? "none" : "auto" }} items={itemsTabPane} type="card" activeKey={defaultActiveKey} onChange={setDefaultActiveKey} />

        {openResume && (
          <ResumeManager
            fileTitle={fileTitle}
            setFileTitle={setFileTitle}
            setData={setData}
            handleAutoCreateWithFileLead={handleAutoCreateWithFileLead}
            data={!isEmpty(specificLead) ? specificLead : data}
            isEdit={!isEmpty(specificLead)}
            onClose={closeResume}
            onUpload={handleUploadResume}
          />
        )}
        {confirmModal && (
          <DiscardChanges
            confirmModal={confirmModal}
            handleCancelConfirmModal={() => setConfirmModal(false)}
            onSubmitConfirmModal={onClose}
          />
        )}
      </Modal>
    </>
  )
}

export default withMemo(AddLead)
